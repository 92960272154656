import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { AppContext } from "../config/common";
import { CartService } from "../services/cart";
import { PaymentService } from "../services/payment";
import Catalog from "../Catalog";
import { Card, Form, Table } from "react-bootstrap";
import { StoreService } from "../services/store";
import { Link, Navigate } from "react-router-dom";
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import FormField from "../widgets/FormField";
import CartPage from "../widgets/CartPage";
import { SiteService } from "../services/site";
import Delete from "./../images/Delete.svg";
import SimilarProducts from "../widgets/SimilarProducts";

class CartController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    const frmId = "frmLogin" + new Date().getTime();
    this.state = {
      ...props,
      isloaded: false,
      isloading: false,
      isSubmit: false,
      success: null,
      error: null,
      billingAddress: {
        name: "",
        phone: "",
        email: "",
        address: "",
        pincode: "",
      },
      sameAddress: "No",
      shippingAddress: {
        name: "",
        phone: "",
        email: "",
        address: "",
        pincode: "",
      },
      orderdate: "",
      formId: frmId,
      formData: {
        identifier: null,
        password: null,
      },
      errorData: {
        identifier: null,
        password: null,
      },
      redirectToSuccess: false,

    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidUpdate(){
    if (!this.state.isloaded && Catalog.isLoad) {
      if(!this.intervalId){
        this.intervalId = setInterval(this.refreshlist, 1000);
        this.initializeRazorpay();
      }
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.intervalId = setInterval(this.refreshlist, 1000);
    this.initializeRazorpay();
    document.body.classList.add('hidesearchclass');
  }

  componentWillUnmount() {

    clearInterval(this.intervalId);

    if (this.razorscript) {
      document.body.removeChild(this.razorscript);
      this.razorscript = null;
      document.body.classList.remove('hidesearchclass');
    }

  }
  refreshlist = async () => {
    if (!this.state.isloaded && Catalog.isLoad) {
      await Catalog.refreshConfig();
      if (Catalog.Session.isLogined) {
        const billingAddress = {
          name: Catalog.Session.user.first_name + " " + Catalog.Session.user.last_name,
          phone: Catalog.Session.user.mobile,
          email: Catalog.Session.user.email,
          address: "",
          pincode: "",
        }
        this.setState({ billingAddress })
      }
    }
    const isloaded = Catalog.isLoad;

    this.setState({ isloaded })
  }

  handleBillingChange = (e) => {
    const { name, value } = e.target;
    const billingAddress = {
      ...this.state.billingAddress,
      [name]: value
    }
    this.setState({ billingAddress })
    if (this.state.sameAddress === "Yes") {
      const shippingAddress = {
        ...billingAddress,
      }
      this.setState({ shippingAddress })
    }
  }
  handleShippingChange = (e) => {
    const { name, value } = e.target;
    const shippingAddress = {
      ...this.state.shippingAddress,
      [name]: value
    }
    this.setState({ shippingAddress })
  }


  // fetchAccount = async () => {
  //   const id = Catalog.Session.user.id;
  //   const account = await StoreService.fetchAccount(id);
  //   const accountFormData = { first_name: account.first_name, last_name: account.last_name };
  //   this.setState({ account, accountFormData });
  //   if (account.role.id === 5 || account.role.id === 6) {
  //     this.fetchLawyer();
  //   }
  //   else {
  //     const billing_address = this.state.billing_address;

  //     this.setState({ billing_address });
  //   }
  //   if (account.role.id === 4) {
  //     this.fetchOrder();
  //   }
  // };

  // fetchOrder = async () => {
  //   const id = Catalog.Session.user.id;
  //   const orders = await StoreService.fetchOrder(id);
  //   this.setState({ orders });
  // };

  // fetchLawyer = async () => {
  //   const id = Catalog.Session.user.id;
  //   const profile = await StoreService.fetchLawyer(id);
  //   const { PrimaryImageId, SecondaryImageIds, image, Category, ...FormData } = profile;
  //   const profileFormData = {
  //     ...FormData,
  //     PrimaryImageId: PrimaryImageId?.data?.id ?? null,
  //     SecondaryImageIds: (SecondaryImageIds?.data || []).map((im) => im.id)
  //   }

  //   const billing_address = this.state.profileFormData?.contact?.address;

  //   this.setState({ profile, profileFormData, billing_address });
  // };



  RemovetoCart = async (id) => {
    await Catalog.removeFromCart(id)
  };

  // calculateTotalPrice() {

  //   return this.state.cartItems.reduce((total, cartItem) => {
  //     const price = cartItem.catalog_book?.data?.[0]?.attributes?.Price;
  //     return total + (parseFloat(price) || 0);
  //   }, 0).toFixed(2);
  // }

  handleRadioChange = (e) => {
    const value = e.target.value;
    let shippingAddress = this.state.shippingAddress;
    if (value === "Yes") {
      shippingAddress = {
        ...this.state.billingAddress
      }
    }
    this.setState({ sameAddress: value, shippingAddress });
  };

  // updateShippingAddress = () => {

  //   const Name = Catalog.Session.user.first_name + "" + Catalog.Session.user.last_name;

  //   if (this.state.sameAddress === "Yes") {

  //     this.setState({
  //       shippingAddress: {
  //         name: Name,
  //         phone: Catalog.Session.user.mobile,
  //         address: this.state.profileFormData?.contact?.address,
  //       },
  //     });


  //   } else {
  //     this.setState({
  //       shippingAddress: {
  //         name: "",
  //         phone: "",
  //         address: "",
  //       },
  //     });
  //   }
  // };

  // handleShippingChange = (e) => {
  //   const { name, value } = e.target;
  //   this.setState({
  //     shippingAddress: {
  //       ...this.state.shippingAddress,
  //       [name]: value,
  //     },
  //     nameError: '',
  //     phoneError: '',
  //     addressError: '',
  //   });
  // };


  // getAddtess = (e) => {
  //   const { name, value } = e.target;

  //   const billing_address = value;

  //   this.setState({ billing_address });

  // };

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { settings } = this.context;
    let formData = this.state.formData;
    let errorData = {};
    let isInValid = false;
    for (const name in formData) {
      errorData[name] = "The " + name + " field is required";
      if (Object.hasOwnProperty.call(formData, name)) {
        if (formData[name] && formData[name] !== "") {
          errorData[name] = null;
        } else {
          isInValid = true;
        }
      } else {
        isInValid = true;
      }
    }
    let isSuccess = false;
    let isSubmit = isInValid ? false : true;
    let error = null
    this.setState({ errorData, isSubmit, isSuccess, error });
    if (!isInValid) {
      const res = await SiteService.loginUser(formData, settings);
      if (res.user) {
        await Catalog.updateCartUser(res.user)
        Catalog.Session = {
          isLogined: true,
          token: res.jwt,
          user: res.user,
        };
        
        const billingAddress = {
          name: res.user.first_name + " " + res.user.last_name,
          phone: res.user.mobile,
          email: res.user.email,
          address: "",
          pincode: "",
        }
        this.setState({ billingAddress })        
        if (this.state.sameAddress === "Yes") {
          const shippingAddress = {
            ...billingAddress,
          }
          this.setState({ shippingAddress })
        }
      }
      if (res.error) {
        error = res.error.message;
      }
      isSubmit = false;
    }
    this.setState({ errorData, isSubmit, isSuccess, error });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData });
    if (!value || value === "") {
      const errorData = {
        ...this.state.errorData,
        [name]: "The " + name + " field is required",
      };
      this.setState({ errorData });
    } else {
      const errorData = {
        ...this.state.errorData,
        [name]: null,
      };
      this.setState({ errorData });
    }
  };
  handleCheckoutSubmit = async (e) => {
    e.preventDefault();
    if (!window.Razorpay) return;
    try {
      if (Catalog.Cart.items.length == 0) {
        throw new Error('Failed to create an order');

      }
      const data = {
        billingAddress: this.state.billingAddress,
        shippingAddress: this.state.shippingAddress,
        sameAddress: this.state.sameAddress
      }
      const payment = await PaymentService.checkoutCart(data);


      const order = payment.order;
      const orderdate = payment.order.created_at;

      this.setState({ orderdate });
      console.log(payment, 'puyui');
      if (!order || !order.id) {
        throw new Error('Failed to create an order');
      }

      const options = {
        key: 'rzp_test_6psStwFrJ906iD',
        amount: order.amount,
        currency: order.currency,
        order_id: order.id,
        prefill: {
          name: this.state.billingAddress.name,
          email: this.state.billingAddress.email,
          contact: this.state.billingAddress.mobile,
        },
        theme: {
          color: '#F37254',
        },
        handler: this.handlePaymentResponce
      };
      const rzp = new window.Razorpay(options);

      
      await rzp.open();

    } catch (error) {

    }
  };
  handlePaymentResponce = async (response) => {

    console.log(Catalog.Cart.items);

    const orderdate = this.state.orderdate;

    const data = {
      billingAddress: this.state.billingAddress,
      shippingAddress: this.state.shippingAddress,
      sameAddress: this.state.sameAddress,
      name: this.state.billingAddress.name,
      email: this.state.billingAddress.email,
      contact: this.state.billingAddress.mobile,
      order: response,
      cart: Catalog.Cart.items,
      orderdate: orderdate
    }
    const { settings } = this.context;
   
console.log(settings);
    const mailpayment = await PaymentService.paymentUserMail(data);
    
    // const contact=await PaymentService.paymentUserMailAdmin(data,settings);

    Catalog.resetCart();
    const redirectToSuccess =true;
    await PaymentService.verifyPayment(response);
    this.setState({ redirectToSuccess })
  }

  initializeRazorpay = () => {
    this.razorscript = document.createElement("script");
    this.razorscript.src = "https://checkout.razorpay.com/v1/checkout.js";
    this.razorscript.async = true;
    document.body.appendChild(this.razorscript);
  }

  payNow = async () => {

    // console.log('Starting payment...');

    // const user_id = Catalog.Session.user.id;
    // const profile = Catalog.Session.user;

    // const fullname = profile.first_name + ' ' + profile.last_name;

    // const email = profile.email;

    // const contact = profile.mobile;

    // const Batch_ids = this.state.cartItems.map((cartItem) => cartItem.Batch_id);

    // const billing_name = this.state.billing_name;

    // const biling_phone = this.state.biling_phone;

    // const billing_address = this.state.billing_address;

    // const shipping_name = this.state.shippingAddress.name;
    // const shipping_phone = this.state.shippingAddress.phone;

    // const shipping_address = this.state.shippingAddress.address;


    // if (!window.Razorpay) return;
    // try {
    //   console.log('console...');
    //   // const response = await request.post('https://techlegalonline.com/api/payment/payment', {data:{ user_id:user_id,book_id:book_id,Price:Price }});
    //   // const response = await request.post('http://localhost:1337/api/payment', {data:{ user_id:user_id,book_id:book_id,Price:Price }});

    //   // const data = await response.json();

    //   const payment = await PaymentService.submitPayment(user_id, Batch_ids);

    //   console.log('test');
    //   console.log(payment);

    //   const order = payment.order;

    //   if (!order || !order.id) {
    //     throw new Error('Failed to create an order');
    //   }

    //   const options = {
    //     key: 'rzp_test_6psStwFrJ906iD',
    //     amount: order.amount,
    //     currency: order.currency,
    //     //  name: bookname,
    //     //  description: description,
    //     order_id: order.id,
    //     callback_url: 'https://techlegalonline.com/api/payment/success',
    //     prefill: {
    //       name: fullname,
    //       email: email,
    //       contact: contact,
    //     },
    //     theme: {
    //       color: '#F37254',
    //     },
    //   };
    //   console.log(options);

    //   const rzp = new window.Razorpay(options);
    //   await rzp.open();
    // } catch (error) {
    //   console.error('Error during payment:', error);
    //   alert('Payment failed. Please try again.');
    // }
  };


  updateCartCount = async (id, count) => {
    if (!this.state.isloading) {
      this.setState({ isloading: true })
      await Catalog.updateCartCount(id, count)
    }
    this.setState({ isloading: false })
  }


  render() {

   

    if (this.state.redirectToSuccess) {
      return <Navigate to="/success" />;
    }
    if (this.state.isloaded === true) {
      let totalamount = 0;
      for (let idx = 0; idx < Catalog.Cart.items.length; idx++) {
        totalamount += Catalog.Cart.items[idx].count * Catalog.Cart.items[idx].Price;

      }
      const { settings } = this.context;

      return (
        <>
          <div className="cart-wrap">
            <div className="top-action">
              <Link to={"/catalogs/books"}> &lt; Back</Link>
            </div>
            {/* <div className="cart-l">
              <CartPage cartdetaipage={Catalog.Cart.items}/>
               </div> */}


            <div className="row">
              <div className="col-md-9">
              <Card className="cart-c">
                  <Card.Header>
                    <span>
                      Showing Products ({Catalog.Cart.items.length})
                    </span>
                  </Card.Header>

                  <Card.Body>
                    <Table responsive="xs">
                    
                      <tbody>
                        {Catalog.Cart.items.map((cartItem, index) => (
                        <div className="cartcard" key={cartItem.id}>
                          <div className="Cart-content">
                            <div className="Cart-content-section">
                            
                              <div className="cart-avatar">
                                <img
                                  className="cart-content-main"
                                  src={cartItem.image}
                                  alt={cartItem.Title || "Book Title"}
                                />
                              </div>

                            
                              <div className="cart-content-left">
                                <h4 className="cart-title">{cartItem.Title || "Book Title"}</h4>
                                <div className="cart-content-quantity">
                                
                                  <Button type="button" className="button-minus" disabled={this.state.isloading} size="sm" onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (cartItem.count > 1) {
                                                          this.updateCartCount(cartItem.id, cartItem.count - 1)
                                                        }
                                                      }}>-</Button>

                                
                                  <p className="cart-qty">{cartItem.count || ""}</p>

                                
                                  <Button type="button" className="button-plus" disabled={this.state.isloading} size="sm" onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        // if(cartItem.count>1){
                                                        this.updateCartCount(cartItem.id, cartItem.count + 1)
                                                        // }
                                                      }}>+</Button>
                                </div>
                              </div>

                            
                              <div className="cart-content-right">
                                <h4 className="cart-price">₹{cartItem.Price || ""}</h4>
                                <div className="cart-right-btn">
                                <img src={Delete} alt="" className="rightt-btn-img" />
                                  <button
                                    className="cart-remove-btn"
                                    onClick={() => this.RemovetoCart(cartItem.id)}
                                  >                                  
                                    Delete
                                  </button>

                                 


                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}

                      </tbody>

                      <tfoot>
                        <tr>
                        
                          <td colSpan={2} >

                            <div className="price-section">
                              <span>Shiping charge</span>
                            </div>
                          </td>
                          <td>
                            <div className="price-section">
                              <span>: ₹100</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          
                          <td colSpan={2}>
                            <div className="price-section">
                              <span>Total Price</span>
                            </div>
                          </td>
                          <td  >
                            <div className="price-section">
                              <span>: ₹{totalamount + 100}</span>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>

                    <Button variant="primary" type="button" onClick={this.handleCheckoutSubmit} >
                      Check Out
                    </Button>
                  </Card.Body>
                  </Card>
                  <div className="lower similar-cart ">
          <h2 className="other">Similar Products</h2>
          <SimilarProducts />
        </div>
              </div>
             
              <div className="col-md-3 loginclass">
                {!Catalog.Session.isLogined && (
                  <Card>
                    <div className="customer-login">
                        <h2>Customer Login</h2>
                        {/* <img src={logo} alt="logo" /> */}

                        {
                        this.state.error && (<>
                          <Alert variant="danger" dismissible>
                            <p>
                              {this.state.error}
                            </p>
                          </Alert>

                        </>)
                      }
                      {
                        Catalog.isMessage && (<>
                          <Alert variant="success" dismissible>
                            <p>
                              {Catalog.Message}
                            </p>
                          </Alert>
                        </>)
                      }
                      <Form
                        id={this.state.formId}
                        className="loginForm"
                        onSubmit={this.handleSubmit}
                        noValidate
                      >
                        <FormField
                          id={this.state.formId + "identifier"}
                         
                          // placeholder={"Email Id"}
                          value={this.state.formData?.identifier}
                          error={this.state.errorData?.identifier}
                          name={"identifier"}
                          placeholder="Email id"
                          handle={this.handleChange}
                        />
                        <FormField
                          id={this.state.formId + "password"}
                          type={"password"}
                         
                          placeholder="Password"
                          value={this.state.formData?.password}
                          error={this.state.errorData?.password}
                          name={"password"}
                          handle={this.handleChange}
                        />
                        <Link to={"/forgot-password"} className="frgtpw">
                          Forgot Password?
                        </Link>
                        <div className="formfield">
                          <Button
                            variant="primary"
                            className="loginBtn1"
                            type="submit"
                            disabled={this.state.isSubmit}
                          >
                            Login
                          </Button>
                        </div>
                        <div className="loginFooter">
                          <p>
                            New to {settings.title}?<Link to={"/signup"}>Register</Link>
                          </p>
                        </div>
                      </Form>
                      </div>
                     
                  </Card>)}

                 


                <Card className="mb-2 mt-2 billingclass">
                  <Card.Header>
                    <span>
                      Billing Address
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <div className="cart-items">
                      <Form.Group className="mb-6" controlId="formName">
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name"
                          name="name"
                          value={this.state.billingAddress.name}
                          onChange={this.handleBillingChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-6" controlId="formEmail">
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email"
                          name="email"
                          value={this.state.billingAddress.email}
                          onChange={this.handleBillingChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-6" controlId="formPhone">
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your phone number"
                          name="phone"
                          value={this.state.billingAddress.phone}
                          onChange={this.handleBillingChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-6" controlId="formAddress">
                        <Form.Label></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter your address"
                          name="address"
                          value={this.state.billingAddress.address}
                          onChange={this.handleBillingChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-6" controlId="formPin">
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter your Pincode"
                          name="pincode"
                          value={this.state.billingAddress.pincode}
                          onChange={this.handleBillingChange}
                        />
                      </Form.Group>

                     

                    </div>
                  </Card.Body>

                </Card>

                <Card className="mt-2 shippingclass">
                  <Card.Header>
                    <span>
                      Shipping Details
                    </span>
                  </Card.Header>
                  <Form.Group className="mb-3" controlId="formRadio">
                        <Form.Label>Is Shipping Address same as Billing Address?</Form.Label>
                        <div>
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="sameAddress"
                            value="Yes"
                            onChange={this.handleRadioChange}
                            id="shippingYes"
                            checked={this.state.sameAddress === "Yes"}
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="sameAddress"
                            value="No"
                            onChange={this.handleRadioChange}
                            id="shippingNo"
                            checked={this.state.sameAddress !== "Yes"}
                          />
                        </div>
                      </Form.Group>
                  <Card.Body>
                    <div className="cart-items">

                      <Form.Group className="mb-6" controlId="formNameShiping">
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name"
                          name="name"
                          onChange={this.handleShippingChange}
                          value={this.state.shippingAddress.name}
                          readOnly={this.state.sameAddress === "Yes"}
                        />
                      </Form.Group>

                      {/* <Form.Group className="mb-6" controlId="formEmailShiping">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email"
                          name="email"
                          value={this.state.shippingAddress.email}
                          onChange={this.handleShippingChange}
                          readOnly={this.state.sameAddress === "Yes"}
                        />
                      </Form.Group> */}
                      <Form.Group className="mb-6" controlId="formPhoneShiping">
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your phone number"
                          name="phone"
                          onChange={this.handleShippingChange}
                          value={this.state.shippingAddress.phone}
                          readOnly={this.state.sameAddress === "Yes"}

                        />
                      </Form.Group>

                      <Form.Group className="mb-6" controlId="formAddressShiping">
                        <Form.Label></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter your address"
                          name="address"
                          onChange={this.handleShippingChange}
                          value={this.state.shippingAddress.address}
                          readOnly={this.state.sameAddress === "Yes"}
                        />
                      </Form.Group>

                      <Form.Group className="mb-6" controlId="formPinShiping">
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter your Pincode"
                          name="pincode"
                          value={this.state.shippingAddress.pincode}
                          onChange={this.handleShippingChange}
                          readOnly={this.state.sameAddress === "Yes"}
                        />
                      </Form.Group>

                    </div>
                  </Card.Body>

                </Card>

              </div>
            </div>
          </div>
        </>
      );

    } else {

      return <>Loading</>;
    }
  }
}
export default CartController;
