import React, { Component } from "react";
import Catalog from "../Catalog";
import { AppContext, titleSlug } from "../config/common";
import { Link, Navigate } from "react-router-dom";
import { StoreService } from "../services/store";

class FindPracticeLawyerController extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            isloaded: false,
            practiceItem: {},
            redirectToLawyers:false,
            availableFilterData: { 
              PropertyList1:[],
              PropertyList2:[],
              PropertyList3:[],
              Property1:[],
              Property2:[],
              Category:[],
              author_names:[],
              publishers:[],  
              SubTitle:[],
              Price:[],
              Binding:[],
              Type:[],
              Search:[],             
            },
        }
    }
    componentDidMount() {
        document.body.classList.add('lawyerclass');
        this.fetchFilterIds();
        const items = [...(Catalog.Lawyer.Config.PropertyList1 || [])].filter((i) => this.state.practice === titleSlug(i.label))
        if (items.length > 0) {
            const practiceItem = items[0];
            this.setState({ practiceItem })
        }
        this.intervalId = setInterval(this.refreshlist, 1000);
    }

    componentWillUnmount() {
        document.body.classList.remove('lawyerclass');
        clearInterval(this.intervalId);
    }


    componentDidUpdate() {
        if (!this.state.practiceItem || this.state.practice !== titleSlug(this.state.practiceItem?.label||"")) {
            const items = [...(Catalog.Lawyer.Config.PropertyList1 || [])].filter((i) => this.state.practice === titleSlug(i.label))
            if (items.length > 0) {
                const practiceItem = items[0];
                this.setState({ practiceItem })
            }
        }
    }

    fetchFilterIds = async () => { 
        const availableFilterData = await StoreService.fetchFilterIds('lawyers'); 
        this.setState({ availableFilterData });
    }
    refreshlist = async () => {
        if (!this.state.isloaded && Catalog.isLoad) {
            await Catalog.refreshConfig();
        }
        const isloaded = Catalog.isLoad;
        this.setState({ isloaded })
    }
    render() {
        if(this.state.redirectToLawyers){
            return <>
            <Navigate replace to={'/catalogs/lawyers'} />
            </>
        }

        if (this.state.isloaded == true) {
            return <div className="find-wrap">
                        <div className="top-action">
                          <Link to={"/find-a-lawyer" }> &lt; Back</Link>
                        </div>
                <div>

                    <div className='lawer-sub-sec all-lawer-sec'>
                        <h2>How to find and hire a great lawyer</h2>
                        <div className="law-text">
                            <p>Lawyers (also called attorneys or counsel) serve as advocates for people and organizations. They represent clients both to the court and to opposing parties. Lawyers can represent clients in criminal cases, where a law has been broken, and in civil cases</p>
                            <p>Lawyers (also called attorneys or counsel) serve as advocates for people and organizations. They represent clients both to the court and to opposing parties. Lawyers can represent clients in criminal cases, where a law has been broken, and in civil cases</p>
                        </div>

                        {/* <div className='pratice-area-lawer'>
                            <div>
                                <p><a>Pratice Areas</a></p>
                            </div>
                            <div>
                                <p><a>Pratice Areas</a></p>
                            </div>
                            <div>
                                <p><a>Pratice Areas</a></p>
                            </div>
                            <div>
                                <p><a>Pratice Areas</a></p>
                            </div>
                        </div>  */}
                    </div>
                </div>
                {/* <h2>See lawyers by practice area</h2>
                <p>Choose an area of law to find top-rated attorneys near you.</p> */}
                <div className='lawer-ul-sec'>
                    <div className='lawer-2-sec l-te'>
                        <h2>View {this.state.practiceItem.label} Lawyers by state </h2>

                        <ul className="lawer-list">
                            {[...(Catalog.Lawyer.Config.Property1 || [])].filter((itm)=>(this.state.availableFilterData.Property1||[]).includes(itm.value)).map((item, index) => (
                                <li key={index} className="lawer-item">
                                    <Link onClick={()=>{
                                    Catalog.Flash={
                                        ...Catalog.Flash,
                                        Property1:[item.value+""],
                                        PropertyList1:[this.state.practiceItem.value+""]
                                    }
                                    this.setState({
                                        redirectToLawyers:true
                                    })
                                }}>{item.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='lawer-3-sec l-te'>
                        <h2>View  {this.state.practiceItem.label} Lawyers by city</h2>

                        <ul className="lawer-list">
                            {[...(Catalog.Lawyer.Config.Property2 || [])].filter((itm)=>(this.state.availableFilterData.Property2||[]).includes(itm.value)).map((item, index) => (
                                <li key={index} className="lawer-item">
                                    <Link onClick={()=>{
                                    Catalog.Flash={
                                        ...Catalog.Flash,
                                        Property2:[item.value+""],
                                        PropertyList1:[this.state.practiceItem.value+""]
                                    }
                                    this.setState({
                                        redirectToLawyers:true
                                    })
                                }}>{item.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='lawer-sub-sec'>
                    <h4>How to find and hire a great lawyer</h4>
                    <h5>Do I need a lawyer?</h5>
                    <div className="law-text">
                        <h6>What do lawyers do, exactly?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>
                    <div className="law-text">
                        <h6>What do lawyers do, exactly?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>
                </div>
            </div>;
        }
        else {
            return <>Loading</>
        }
    }
}
export default FindPracticeLawyerController;