import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { AppContext } from "../config/common";
import { StoreService } from "../services/store";
import Catalog from "../Catalog";
import { Link, Navigate } from "react-router-dom";
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import SimilarProducts from "../widgets/SimilarProducts";

class StoreItemDetailController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      storeItem: {},
      storeName: "",
      storeId: "",
      storeSlug: "",
      loaded: false,
      modal: false,
      isSubmit: false,
      success: null,
      error: null,
      currentimage: null,
      currentImageIndex: 0,
      isloading: false,
      redirectToCart: false,
      isContentVisible: false,
      toggleContent: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {

    window.scrollTo(0, 0);
    this.fetchStoreItem();

    this.intervalId = setInterval(this.refreshlist, 1000);
    document.body.classList.add('detail-classhide'); 
  }

  componentDidUpdate() {
    if (
      this.state.store !== this.state.storeName ||
      this.state.id !== this.state.storeId ||
      this.state.slug !== this.state.storeSlug
    ) {
      this.fetchStoreItem();
    }
  }

  componentWillUnmount() {

    clearInterval(this.intervalId);
    document.body.classList.remove('detail-classhide'); 
  }
  refreshlist = async () => {

    if (!this.state.isloaded && Catalog.isLoad) {
      await Catalog.refreshConfig();
    }
    const isloaded = Catalog.isLoad;
    this.setState({ isloaded })
  }

  handleClose = () => {
    const modal = false;
    this.setState({ modal });
  };
  ucfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  formatDateYMD = (date) => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  handleShow = () => {
    const modal = true;
    this.setState({ modal });
  };
  handleSubmit = async () => {
    let isSubmit = true;
    this.setState({ isSubmit });
    const settings = this.context;
    const orderdate = new Date();
    const expdate = new Date();
    expdate.setMonth(expdate.getMonth() + 1);
    const order = await StoreService.createOrder(
      {
        user_id: Catalog.Session.user.id,
        store_id: this.ucfirst(this.state.store),
        item_id: this.state.storeItem.id.toString(),
        purchase_date: this.formatDateYMD(orderdate),
        expiry_date: this.formatDateYMD(expdate),
        item_detail: this.state.storeItem,
        Price: this.state.storeItem.Price,
      },
      settings
    );
    let success,
      error = null;
    if (order.id || (order.data && order.data.id)) {
      success = "Order was placed";
    } else {
      error = "Error: plese try again later ";
    }

    const modal = false;
    isSubmit = false;
    this.setState({ modal, isSubmit, success, error });
  };

  fetchStoreItem = async () => {
    const storeName = this.state.store;
    const storeId = this.state.id;
    const storeSlug = this.state.slug;
    let loaded = false;
    this.setState({ storeName, storeId, storeSlug, loaded });
    const storeItem = await StoreService.fetchStoreItem(
      storeName,
      storeId,
      storeSlug
    );
    loaded = true;
    const currentImageIndex = 0;
    let currentimage = storeItem.image;
    if (this.state.store !== "books" && storeItem.gallery.length > 0) {
      currentimage = storeItem.gallery[0].image;
    }
    this.setState({ storeItem, loaded, currentimage, currentImageIndex });
  };
  handleChangeCurrentImage = (e) => {
    const { src: currentimage } = e.target;
    let currentImageIndex = 0;
    for (let index = 0; index < this.state.storeItem.gallery.length; index++) {
      if (this.state.storeItem.gallery[index].image === currentimage) {
        currentImageIndex = index + 1;
      }
    }
    this.setState({ currentimage, currentImageIndex });
  };


  handleAddToCart = async (e) => {
    e.preventDefault();
    if (!this.state.isloading) {
      this.setState({ isloading: true })
      await Catalog.addToCart(this.state.storeItem)
      this.setState({ redirectToCart: true })
    }
    this.setState({ isloading: false })
  };

  // toggleContent = () => {
  //   setContentVisible(!this.state.isContentVisible);
  // };
  toggleContent = () => {
    this.setState((prevState) => ({
      isContentVisible: !prevState.isContentVisible,
    }));
  };
  
  render() {

    if (this.state.redirectToCart) {
      return <Navigate to="/cart" />;
    }
    let catalog = Catalog.Book;
    switch (this.state.store) {
      case "books":
        catalog = Catalog.Book;
        break;
      case "lawyers":
        catalog = Catalog.Lawyer;
        break;
      case "firms":
        catalog = Catalog.Firm;
        break;

      default:
        return (
          <>
            <Navigate replace to="/404" />

            <Helmet>

              <title>{this.state.storeItem.Title}</title>

              <meta name="title" content={this.state.storeItem.Title} />

              <meta
                name="keywords"
                content={this.state.storeItem.meta_asp_additional_tags}
              />

            </Helmet>

          </>
        );
    }
    if (this.state.loaded === true) {
      if (!this.state.storeItem.id) {
        return (
          <div className="store-wrap">
            <div className="top-action">
              <Link to={"/catalogs/" + this.state.store}> &lt; Back</Link>
            </div>
            <div className="store-item">
              <div className="store-item-info">
                <div className="store-item-title">
                  <span className="title">Item Not Found</span>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (

        <>

          <Helmet>

            <title>{this.state.storeItem.Title}</title>

            <meta name="title" content={this.state.storeItem.Title} />

            <meta
              name="keywords"
              content={this.state.storeItem.meta_asp_additional_tags}
            />

          </Helmet>

          <div className="store-wrap">
            {this.state.error && (
              <>
                <Alert variant="danger" dismissible>
                  <p>{this.state.error}</p>
                </Alert>
              </>
            )}
            {this.state.success && (
              <>
                <Alert variant="success" dismissible>
                  <p>{this.state.success}</p>
                </Alert>
              </>
            )}
            <div className="top-action">
              <Link to={"/catalogs/" + this.state.store}> &lt; Back</Link>
            </div>
            <div className="store-item-detail">
              {this.state.store === "books" && (
                <div className="store-item-avathar">
                  <div className="store-item-main">
                    <TransitionGroup>
                      <CSSTransition
                        key={this.state.currentImageIndex}
                        timeout={500}
                        classNames="fade"
                      >
                        <img
                          src={this.state.currentimage}
                          alt={this.state.storeItem.Title}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                  {this.state.storeItem.gallery.length > 0 && (
                    <div className="store-item-gallery">
                      <div className="store-gallery-item">
                        <img
                          src={this.state.storeItem.image}
                          currentimage={this.state.storeItem.image}
                          onClick={this.handleChangeCurrentImage}
                          alt={this.state.storeItem.Title}
                        />
                      </div>
                      {this.state.storeItem.gallery.map((v, i) => {
                        return (
                          <div className="store-gallery-item" key={i}>
                            <img
                              src={v.image}
                              currentimage={v.image}
                              onClick={this.handleChangeCurrentImage}
                              alt={this.state.storeItem.Title}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}

              <div className="store-item-info">
                <div className="store-item-title">
                  {this.state.store !== "books" && (
                    <div className="avathar">
                      <img
                        src={this.state.storeItem.image}
                        alt={this.state.storeItem.Title}
                      />
                    </div>
                  )}
                  
                  <div className="title">{this.state.storeItem.Title}</div>
                  {this.state.storeItem.author_names && catalog.Config.author_names && Array.isArray(catalog.Config.author_names) && catalog.Config.author_names.filter((i) => this.state.storeItem.author_names.includes(Number.parseInt(i.value)) && i.label !== null).length > 0 && (
                       <div className="authorclass">
                       <p>By</p>
                       <div>
                         {catalog.Config.author_names
                           .filter(
                             (i) =>
                               this.state.storeItem.author_names.includes(Number.parseInt(i.value)) &&
                               i.label !== null
                           )
                           .map((i, index) => (
                             <span key={i.value}>
                               {index > 0 && ", "}
                               {i.label}
                             </span>
                           ))}
                       </div>
                     </div>
                     
                      )}
                  <td><h2>₹{this.state.storeItem.Price}</h2></td>
                  <div className="subtitle">
                    {this.state.store === "books"}
                    {this.state.storeItem.SubTitle}
                  </div>
                </div>
                <div className="details-section">
                  {this.state.store === "books" && (
                    <p>{this.state.storeItem.Category}</p>
                  )}
                  <table>
                    <tbody>
                      {Catalog.Session.isLogined &&
                        this.state.store !== "books" &&
                        this.state.storeItem.Identifier && (
                          <tr>
                            <th>{catalog.Profile.Identifier}</th>
                            <td> {this.state.storeItem.Identifier}</td>
                          </tr>
                        )}
                      {this.state.storeItem.DisplayDate && (
                        <tr>
                          <th>{catalog.Profile.DisplayDate}</th>
                          <td> {this.state.storeItem.DisplayDate}</td>
                        </tr>
                      )}
                      {this.state.storeItem.shipping_class && (
                        <tr>
                          <th>Shipping Class</th>
                          <td> {this.state.storeItem.shipping_class}</td>
                        </tr>
                      )}
                      {this.state.storeItem.ISBN && (
                        <tr>
                          <th>ISBN</th>
                          <td> {this.state.storeItem.ISBN}</td>
                        </tr>
                      )}
                      {this.state.storeItem.Edition && (
                        <tr>
                          <th>Edition</th>
                          <td> {this.state.storeItem.Edition}</td>
                        </tr>
                      )}

                      {/* {this.state.storeItem.author_names && catalog.Config.author_names && Array.isArray(catalog.Config.author_names) && catalog.Config.author_names.filter((i) => this.state.storeItem.author_names.includes(Number.parseInt(i.value)) && i.label !== null).length > 0 && (
                        <tr>
                          <th>Author</th>
                          <td>
                            {
                              catalog.Config.author_names.filter((i) => this.state.storeItem.author_names.includes(Number.parseInt(i.value)) && i.label !== null).map((i, index) => {
                                return (
                                  <span key={i.value}>
                                    {index > 0 && ", "}
                                    {i.label}
                                  </span>
                                );
                              })}
                          </td>
                        </tr>
                      )} */}


                      {this.state.storeItem.publishers && catalog.Config.publishers && Array.isArray(catalog.Config.publishers) && catalog.Config.publishers.filter((i) => this.state.storeItem.publishers.includes(Number.parseInt(i.value)) && i.label !== null).length > 0 && (
                        <tr>
                          <th>Publisher</th>
                          <td>
                            {catalog.Config.publishers.filter((i) => this.state.storeItem.publishers.includes(Number.parseInt(i.value)) && i.label !== null).map((i, index) => {
                              return (
                                <span key={i.value}>
                                  {index > 0 && ", "}
                                  {i.label}
                                </span>
                              );
                            })}
                          </td>
                        </tr>
                      )}

                      {this.state.storeItem.Pages && (
                        <tr>
                          <th>Pages</th>
                          <td> {this.state.storeItem.Pages}</td>
                        </tr>
                      )}
                      {this.state.storeItem.Binding && (
                        <tr>
                          <th>Binding</th>
                          <td> {this.state.storeItem.Binding}</td>
                        </tr>
                      )}

                      {this.state.storeItem.PropertyList1 && catalog.Config.PropertyList1 && Array.isArray(catalog.Config.PropertyList1) && catalog.Config.PropertyList1.filter((i) => this.state.storeItem.PropertyList1.includes(Number.parseInt(i.value))).length > 0 && (
                        <tr>
                          <th>{catalog.Profile.PropertyList1}</th>
                          <td>

                            {
                              catalog.Config.PropertyList1.filter((i) => this.state.storeItem.PropertyList1.includes(Number.parseInt(i.value))).map((i) => (<Badge bg="dark" key={i.value}>{i.label}</Badge>))
                            }
                          </td>
                        </tr>
                      )}

                      {Catalog.Session.isLogined &&
                        this.state.store !== "books" && (
                          <>
                            {this.state.storeItem.contact.email_address && (
                              <tr>
                                <th>{catalog.Profile.email_address}</th>
                                <td>
                                  {" "}
                                  {this.state.storeItem.contact.email_address}
                                </td>
                              </tr>
                            )}
                            {this.state.storeItem.contact.phone_number && (
                              <tr>
                                <th>{catalog.Profile.phone_number}</th>
                                <td>
                                  {" "}
                                  {this.state.storeItem.contact.phone_number}
                                </td>
                              </tr>
                            )}
                            {this.state.storeItem.contact.website_link && (
                              <tr>
                                <th>{catalog.Profile.website_link}</th>
                                <td>
                                  {" "}
                                  <a
                                    href={
                                      this.state.storeItem.contact.website_link
                                    }
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    {this.state.storeItem.contact.website_link}
                                  </a>{" "}
                                </td>
                              </tr>
                            )}
                            {this.state.storeItem.contact.address && (
                              <tr>
                                <th>{catalog.Profile.address}</th>
                                <td> {this.state.storeItem.contact.address}</td>
                              </tr>
                            )}
                          </>
                        )}

                      {/* {Catalog.Session.isLogined &&
                        this.state.storeItem.Price && (
                          <tr>
                            <th>{catalog.Profile.Price}</th>
                            <td> {this.state.storeItem.Price}</td>
                          </tr>
                        )} */}
                    </tbody>
                  </table>
                </div>
                <div className="cart">
                  {this.state.store === "books" ? (<Link
                    className="add-to-cart"
                    onClick={this.handleAddToCart}
                  >
                    Add to Carts {this.state.isloading && (<Spinner size="sm" />)}
                  </Link>) : (
                    <>
                      {!Catalog.Session.isLogined && (
                        <Link
                          to={"/login"}
                          className="book-now-btn"
                          onClick={() => {
                            Catalog.RediarectLogin = `/catalogs/${this.state.store}/${this.state.id}/${this.state.slug}`;
                          }}
                        >
                          Contact
                        </Link>
                      )}
                    </>
                  )}
                  {/* {Catalog.Session.isLogined ? (
                  <>
                    {this.state.store === "books" && (
                      <>
                        <Link
                          className="book-now-btn"
                          onClick={this.handleShow}
                        >
                          Book Now
                        </Link>
                        <Modal
                          show={this.state.modal}
                          onHide={this.handleClose}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              {this.state.storeItem.Title}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <table>
                              <tbody>
                                {this.state.storeItem.Identifier && (
                                  <tr>
                                    <th>{catalog.Profile.Identifier}</th>
                                    <td> {this.state.storeItem.Identifier}</td>
                                  </tr>
                                )}
                                {this.state.storeItem.DisplayDate && (
                                  <tr>
                                    <th>{catalog.Profile.DisplayDate}</th>
                                    <td> {this.state.storeItem.DisplayDate}</td>
                                  </tr>
                                )}
                                {this.state.storeItem.shipping_class && (
                                  <tr>
                                    <th>{catalog.Profile.DisplayDate}</th>
                                    <td> {this.state.storeItem.shipping_class}</td>
                                  </tr>
                                )}
                                {this.state.storeItem.PropertyList1 && catalog.Config.PropertyList1 &&  catalog.Config.PropertyList1.length > 0 && (
                                  <tr>
                                    <th>{catalog.Profile.PropertyList1}</th>
                                    <td>
                                  
                                      {(Array.isArray(catalog.Config.PropertyList1) && catalog.Config.PropertyList1.length > 0) ? (
                                        catalog.Config.PropertyList1.map((i) => (
                                        
                                          this.state.storeItem.PropertyList1 && this.state.storeItem.PropertyList1.includes(Number.parseInt(i.value)) && (
                                            <Badge bg="dark" key={i.value}>{i.label}</Badge>
                                          )
                                        ))
                                      ) : (
                                        <span>No properties available</span>
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {Catalog.Session.isLogined &&
                                  this.state.storeItem.Price && (
                                    <tr>
                                      <th>{catalog.Profile.Price}</th>
                                      <td> {this.state.storeItem.Price}</td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={this.handleClose}
                            >
                              Close
                            </Button>
                            <Link
                                    to={`/payment2/${this.state.store}/${this.state.id}/${this.state.slug}`}
                                    className="book-now-btn"
                                    onClick={() => {
                                      Catalog.RediarectLogin = `/catalogs/${this.state.store}/${this.state.id}/${this.state.slug}`;
                                    }}
                                  >
                                    {this.state.store === "books" ? (
                                      <> Confirm</>
                                    ) : (
                                      <>Contact</>
                                    )}
                          </Link>
                          </Modal.Footer>
                        </Modal>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Link
                      to={"/login"}
                      className="book-now-btn"
                      onClick={() => {
                        Catalog.RediarectLogin = `/catalogs/${this.state.store}/${this.state.id}/${this.state.slug}`;
                      }}
                    >
                      {this.state.store === "books" ? (
                        <> Book Now</>
                      ) : (
                        <>Contact</>
                      )}
                    </Link>
                  </>
                )} */}
                </div>
                <div className="additional-info">
          <hr />
          <h3 onClick={this.toggleContent}>
            About the Book <span>{this.state.isContentVisible ? "-" : "+"}</span>
          </h3>
          <hr />
        </div>

        {this.state.isContentVisible && (
          <div
            className="store-item-content"
            dangerouslySetInnerHTML={{
              __html: this.state.storeItem.Detail1 || "",
            }}
          ></div>
        )}
    
              </div>
              {this.state.store !== "books" &&
                this.state.storeItem.gallery.length > 0 && (
                  <div className="store-item-avathar">
                    <div className="store-item-main">
                      <TransitionGroup>
                        <CSSTransition
                          key={this.state.currentImageIndex}
                          timeout={500}
                          classNames="fade"
                        >
                          <img
                            src={this.state.currentimage}
                            alt={this.state.storeItem.Title}
                          />
                        </CSSTransition>
                      </TransitionGroup>
                    </div>
                    {this.state.storeItem.gallery.length > 1 && (
                      <>
                        <div className="store-item-gallery">
                          {this.state.storeItem.gallery.map((v, i) => {
                            return (
                              <div className="store-gallery-item" key={i}>
                                <img
                                  src={v.image}
                                  currentimage={v.image}
                                  onClick={this.handleChangeCurrentImage}
                                  alt={this.state.storeItem.Title}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                )}
              {/* <div
                className="store-item-content"
                dangerouslySetInnerHTML={{
                  __html: this.state.storeItem.Detail1 || "",
                }}
              ></div> */}
            </div>
          </div>

          <div className="lower">
          <h2 className="other">Similar Products</h2>
          <SimilarProducts />
        </div>

        </>

      );
    } else {
      return <>Loading</>;
    }
  }
}
export default StoreItemDetailController;
